import React from 'react';
import config from '../../../config/config'

const WebDevelopment = () => {
    return (<>
        <p className='mb-5'><b>Welcome to {config.appShortName}'s Website Development Services</b> We specialize in transforming your digital ideas into reality, creating websites that not only look stunning but also drive business growth.</p>
        <h3 class="mb-4">Our Working Process</h3>
        <ul class="list-note">
            <li>
                <span>01</span>
                <div>
                    <h5>Project Research</h5>
                    <p>Understand the purpose of the website, target audience, and main goals. This step involves researching competitors and defining the site’s objectives.</p>
                </div>
            </li>
            <li>
                <span>02</span>
                <div>
                    <h5>Planning & Design</h5>
                    <p>Create a sitemap and wireframes to outline the website’s structure and layout. Develop the visual style of the website, including color schemes, typography, and images.</p>
                </div>
            </li>
            <li>
                <span>03</span>
                <div>
                    <h5>Development</h5>
                    <p>Convert the design into a functional website.</p>
                </div>
            </li>
            <li>
                <span>04</span>
                <div>
                    <h5>Finalization</h5>
                    <p>Test the website for functionality, compatibility, and performance across different devices and browsers.</p>
                </div>
            </li>
        </ul>
        <p className='mt-5'><b>Ready to Start Your Project?</b> Contact us today to discuss your website development needs. Let's create something amazing together!</p>
    </>);
}

export default WebDevelopment;